import TagManager from 'react-gtm-module';

export const initializeGTM = () => {
  const tagManagerArgs = {
    gtmId: process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID,
  };
  TagManager.initialize(tagManagerArgs);
};

export const customPageInitializeGTM = () => {
  const tagManagerArgsCustomPage = {
    gtmId: process.env.REACT_APP_CUSTOM_PAGE_GOOGLE_TAG_MANAGER_ID,
  };
  TagManager.initialize(tagManagerArgsCustomPage);
};
