/* eslint-disable no-console */
import './App.css';
import React, {
  Suspense,
  useContext,
  useEffect,
  useState,
  useCallback,
} from 'react';
import { useSelector } from 'react-redux';
import { Route, Switch, useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import IntelisysPlausibleProvider from './layouts/IntelisysPlausibleProvider';
import SpinnerLoader from './animations/spinnerLoader';
import useWebSocket from './hooks/useWebSocket';
import handleNotification from './utils/handleNotification';
import { customPageInitializeGTM, initializeGTM } from './utils/gtm';
import { NotificationContext } from './utils/notificationContext';

const LoginOTP = React.lazy(() =>
  import('./components/loginAndSignup/otpLogin')
);
const AdminHome = React.lazy(() =>
  import('./components/admin/adminNavButtons')
);
const UserProfileDashboard = React.lazy(() =>
  import('./components/userProfile/myProfile')
);
const WebsiteDisclaimer = React.lazy(() =>
  import('./components/intelisysWebsite/policies/disclaimer')
);
const WebsiteDisclosure = React.lazy(() =>
  import('./components/intelisysWebsite/policies/disclosure')
);
const WebsitePrivacyPolicy = React.lazy(() =>
  import('./components/intelisysWebsite/policies/privacyPolicy')
);
const WebsiteRefundPolicy = React.lazy(() =>
  import('./components/intelisysWebsite/policies/refundPolicy')
);
const WebsiteAboutUs = React.lazy(() =>
  import('./components/intelisysWebsite/policies/aboutUs')
);
const WebsiteContactUs = React.lazy(() =>
  import('./components/intelisysWebsite/policies/contactUs')
);
const PlanDetails = React.lazy(() =>
  import('./components/discoverPlans/planDetails')
);
const WebsiteHomePage = React.lazy(() =>
  import('./components/intelisysWebsite/homePage')
);
const Pricing = React.lazy(() =>
  import('./components/intelisysWebsite/policies/pricing')
);
const Investor = React.lazy(() =>
  import('./components/intelisysWebsite/policies/investor')
);
const Grievance = React.lazy(() =>
  import('./components/intelisysWebsite/policies/grievance')
);
const Complaint = React.lazy(() =>
  import('./components/intelisysWebsite/policies/complaint')
);
const PerformanceDashboard = React.lazy(() =>
  import('./components/performanceCallTracker/dashboard')
);
const ViewAllPlans = React.lazy(() =>
  import('./components/discoverPlans/viewAllPlans')
);
const WatchListComponent = React.lazy(() =>
  import('./components/watchList/wachListComponent')
);
const MainDashboard = React.lazy(() =>
  import('./components/dashboard/mainDashboard')
);
const BuySingleCallCart = React.lazy(() =>
  import('./components/todaysPick/buySingleCall')
);
const BuyAnalysisCart = React.lazy(() =>
  import('./components/todaysPick/buyAnalysis')
);
const MyCart = React.lazy(() => import('./components/cart/myCart'));
const CallDetails = React.lazy(() =>
  import('./components/discoverPlans/callDetails')
);
const Blogs = React.lazy(() => import('./components/intelisysWebsite/blogs'));
const FAQ = React.lazy(() => import('./components/intelisysWebsite/faq'));
const PerformanceReport = React.lazy(() =>
  import('./components/intelisysWebsite/performanceReport')
);
const SEBICompliances = React.lazy(() =>
  import('./components/intelisysWebsite/sebiCompliances')
);
const MobileFAQ = React.lazy(() =>
  import('./components/intelisysWebsite/mobileFAQ')
);
const TermsConditionsData = React.lazy(() =>
  import('./components/acceptTermsConditions/termsConditionsData')
);
const PlanDetailsMobile = React.lazy(() =>
  import('./components/discoverPlans/planDetailsMobile')
);
const TermsConditions = React.lazy(() =>
  import('./components/intelisysWebsite/policies/termsConditions')
);
const OTPVerify = React.lazy(() =>
  import('./components/loginAndSignup/otpVerify')
);
const ChartWebView = React.lazy(() =>
  import('./components/charts/ChartWebView')
);
const MobilePaymentGateway = React.lazy(() =>
  import('./components/cart/mobilePaymentGateway')
);
const ReferAndEarn = React.lazy(() =>
  import('./components/referAndEarn/referAndEarn')
);
const DynamicLinkMobile = React.lazy(() =>
  import('./components/dynamicLinkWebPage/dynamicLinkMobile')
);
const ExploreStories = React.lazy(() =>
  import('./components/exploreStories/stories')
);
const NiftyAndBankNiftyOptionsLandingPage = React.lazy(() =>
  import('./components/customLandingPages/niftyAndBankNiftyOptionsPage')
);
const NiftyAndBankNiftyOptionsLandingPage2 = React.lazy(() =>
  import('./components/customLandingPages/niftyAndBankNiftyOptionsPage2')
);

const ChanakyaPage = React.lazy(() =>
  import('./components/customLandingPages/chanakya/chanakyaPage')
);

const MCXPage = React.lazy(() =>
  import('./components/customLandingPages/mcxLandingPage/mcxPage')
);

const MyCartForOffer = React.lazy(() =>
  import('./components/cart/myCartForOffer')
);

const MyCartOption = React.lazy(() => import('./components/cart/myCartOption'));

const ThankYouOption2 = React.lazy(() =>
  import('./components/customLandingPages/thankYouOption2')
);

const useGTM = (
  excludedPaths,
  isGtmInitialized,
  setGtmInitialized,
  isOutsourceGtmInitialized,
  setOutsourceGtmInitialized
) => {
  const location = useLocation();

  useEffect(() => {
    if (!excludedPaths.includes(location.pathname) && !isGtmInitialized) {
      initializeGTM();
      setGtmInitialized(true);
    }
    if (
      excludedPaths.includes(location.pathname) &&
      !isOutsourceGtmInitialized
    ) {
      customPageInitializeGTM();
      setOutsourceGtmInitialized(true);
    }
  }, [location.pathname, excludedPaths, isGtmInitialized, setGtmInitialized]);
};

const NOTIFICATION_STREAM = `${process.env.REACT_APP_NODE_ENV}_notifications`;

function App() {
  const [isGtmInitialized, setGtmInitialized] = useState(false);
  const [isOutsourceGtmInitialized, setOutsourceGtmInitialized] =
    useState(false);
  const excludedPaths = ['/nifty-and-banknifty-option-1', '/my-cart-offer'];
  useGTM(
    excludedPaths,
    isGtmInitialized,
    setGtmInitialized,
    isOutsourceGtmInitialized,
    setOutsourceGtmInitialized
  );
  const userStoreSession = useSelector(
    (state) => state.UserSessionReducer.userSessionStoreData
  );
  const { socket, isConnected } = useWebSocket(userStoreSession);
  const { handleNotificationClick } = useContext(NotificationContext);
  const notificationHandler = useCallback(
    (notification) => {
      handleNotification(notification, handleNotificationClick);
    },
    [handleNotificationClick]
  );

  useEffect(() => {
    if (socket && isConnected) {
      if (socket.listeners(NOTIFICATION_STREAM).length === 0) {
        socket.on(NOTIFICATION_STREAM, notificationHandler);
      }
    }
    return () => {
      if (socket) {
        socket.off(NOTIFICATION_STREAM, notificationHandler);
      }
    };
  }, [socket, isConnected, userStoreSession]);

  return (
    <>
      <Suspense fallback={<SpinnerLoader isLoading />}>
        <IntelisysPlausibleProvider>
          <Switch>
            <Route exact path='/' component={WebsiteHomePage} />
            <Route exact path='/dashboard' component={MainDashboard} />
            <Route exact path='/pricing' component={Pricing} />
            <Route exact path='/investor-charter' component={Investor} />
            <Route exact path='/complaint-status' component={Complaint} />
            <Route exact path='/grievance-mechanism' component={Grievance} />
            <Route exact path='/admin' component={AdminHome} />
            <Route exact path='/login' component={LoginOTP} />
            <Route exact path='/otp-verify' component={OTPVerify} />
            <Route exact path='/profile' component={UserProfileDashboard} />
            <Route exact path='/watchlist' component={WatchListComponent} />
            <Route exact path='/disclaimer' component={WebsiteDisclaimer} />
            <Route exact path='/disclosure' component={WebsiteDisclosure} />
            <Route
              exact
              path='/privacy-policy'
              component={WebsitePrivacyPolicy}
            />
            <Route
              exact
              path='/refund-policy'
              component={WebsiteRefundPolicy}
            />
            <Route
              exact
              path='/terms-conditions'
              component={TermsConditionsData}
            />
            <Route exact path='/terms-condition' component={TermsConditions} />
            <Route exact path='/about-us' component={WebsiteAboutUs} />
            <Route exact path='/refer-and-earn' component={ReferAndEarn} />
            <Route exact path='/contact-us' component={WebsiteContactUs} />
            <Route exact path='/blogs' component={Blogs} />
            <Route exact path='/faq' component={FAQ} />
            <Route
              exact
              path='/performance-report'
              component={PerformanceReport}
            />
            <Route exact path='/sebi-compliances' component={SEBICompliances} />
            <Route exact path='/performance' component={PerformanceDashboard} />
            <Route exact path='/view-all-plans' component={ViewAllPlans} />
            <Route exact path='/stories' component={ExploreStories} />
            <Route path='/plans/:plan' component={PlanDetails} />
            <Route path='/chart/:analysisData' component={ChartWebView} />
            <Route path='/mobile-plans/:plan' component={PlanDetailsMobile} />
            <Route path='/calls/:callUID' component={CallDetails} />
            <Route exact path='/calls' component={BuySingleCallCart} />
            <Route exact path='/analysis' component={BuyAnalysisCart} />
            <Route exact path='/my-cart' component={MyCart} />
            <Route
              path='/payment-gateway/:userToken'
              component={MobilePaymentGateway}
            />
            <Route exact path='/mobile-faq' component={MobileFAQ} />
            <Route exact path='/dynamic-link' component={DynamicLinkMobile} />
            <Route
              exact
              path='/nifty-and-banknifty-option-1'
              component={NiftyAndBankNiftyOptionsLandingPage}
            />
            {process.env.REACT_APP_NODE_ENV === 'production' && (
              <Route
                exact
                path='/nifty-and-banknifty-option-2'
                component={NiftyAndBankNiftyOptionsLandingPage2}
              />
            )}
            <Route exact path='/chanakya-offer' component={ChanakyaPage} />
            {process.env.REACT_APP_NODE_ENV === 'production' && (
              <Route exact path='/mcx-option-2' component={MCXPage} />
            )}
            <Route exact path='/my-cart-offer' component={MyCartForOffer} />
            {process.env.REACT_APP_NODE_ENV === 'production' && (
              <Route
                exact
                path='/:planName/add-to-cart'
                component={MyCartOption}
              />
            )}
            {process.env.REACT_APP_NODE_ENV === 'production' && (
              <Route
                exact
                path='/:planName/thank-you'
                component={ThankYouOption2}
              />
            )}
          </Switch>
        </IntelisysPlausibleProvider>
      </Suspense>
      <ToastContainer position='top-left' autoClose={3000} hideProgressBar />
    </>
  );
}

export default App;
